import {
  NR_OF_PRODUCTS_PER_PAGE,
  URL_PARAMETERS,
} from "@/components/ProductListV2/constants";
import { useProductListParams } from "@/components/ProductListV2/hooks/useProductListParams";
import { FILTER_SEPARATOR } from "@/react-app/constants";
import { useApiClients } from "@/react-app/contexts/ApiClients";
import { useSharedData } from "@/react-app/contexts/SharedData";
import type { XXLCookie } from "@/react-app/global";
import { useSelectedColumnsNumber } from "@/react-components/ProductList/hooks/useSelectedColumnsNumber";
import type { NumberOfColumnsPerDeviceOnPlp } from "@/react-components/ProductList/hooks/useSelectedColumnsNumber/useSelectedColumnsNumber.types";
import { useElevateRequestData } from "@/react-hooks/useElevateRequestData/useElevateRequestData";
import { useSession } from "@/react-hooks/useSession";
import type { PreferredStores } from "@/react-utils/Cookie";
import { cookieNames, getPreferredStoresCookie } from "@/react-utils/Cookie";
import type { Store as StoreWithId } from "@/react-utils/Stores/stores-helper";
import { getAvailabilityAndStoreIdsFromCookieOrDefault } from "@/utils/search-api-utils/availability-and-store-ids";

/**
 * Returns common request data for product list fetch functions.
 */

export const useCommonProductListRequestData = ({
  initialData,
}: {
  initialData: {
    numberOfColumnsPerDeviceOnPlp: NumberOfColumnsPerDeviceOnPlp | null;
    storeIds: string[];
  };
}) => {
  const { getBaseQuery } = useElevateRequestData();
  const {
    featureToggles: {
      toggle_elevate_cluster_landing_page,
      toggle_force_member_price_display,
    },
    siteUid,
    isTeamsales,
  } = useSharedData().data;
  const { elevateApi, elevateClusterApi } = useApiClients();
  const {
    sessionState: { isLoggedIn },
  } = useSession();
  const { availability, storeIds } =
    getAvailabilityAndStoreIdsFromCookieOrDefault(initialData.storeIds);
  const { gridNumber: numberOfColumnsPerDeviceOnPlp } =
    useSelectedColumnsNumber(initialData.numberOfColumnsPerDeviceOnPlp);
  const {
    parameters: { page, sort },
  } = useProductListParams();

  const fakeXXLCookie: XXLCookie = { loggedIn: isLoggedIn };

  const api = toggle_elevate_cluster_landing_page
    ? elevateClusterApi
    : elevateApi;

  const createCommonRequestData = ({
    selectedFilters,
    preferredStores,
    storesData,
  }: {
    selectedFilters: {
      [key: string]: (string | number)[];
    };
    preferredStores: PreferredStores | null;
    storesData: StoreWithId[];
  }) => {
    const { customerKey, sessionKey } = getBaseQuery();

    return {
      availability,
      cookies: {
        [cookieNames.PREFERRED_STORES]:
          preferredStores !== null
            ? JSON.stringify(preferredStores)
            : JSON.stringify(getPreferredStoresCookie()),
        [cookieNames.XXL]: JSON.stringify(fakeXXLCookie),
      },
      headers: {},
      numberOfProductsPerPage: NR_OF_PRODUCTS_PER_PAGE,
      options: {
        usage: "client-side" as const,
        apiFunction: api.storefront.landingPage,
      },
      isTeamsales,
      query: {
        [URL_PARAMETERS.availability.name]:
          `[${availability.map((a) => `"${a}"`).toString()}]`,
        ...Object.fromEntries(
          Object.entries(selectedFilters).map(([key, value]) => [
            `${URL_PARAMETERS.facet.name}${key}`,
            value.join(FILTER_SEPARATOR),
          ])
        ),
        [URL_PARAMETERS.page.name]: page.toString(),
        [URL_PARAMETERS.sort.name]: sort,
        [URL_PARAMETERS.selectedStores.name]:
          `[${storeIds.map((s) => `"${s}"`).toString()}]`,
      },
      siteUid,
      stores: storesData,
      toggle_elevate_cluster_landing_page,
      toggle_force_member_price_display,
      userKeys: { customerKey, sessionKey },
    };
  };

  return {
    createCommonRequestData,
    numberOfColumnsPerDeviceOnPlp,
  };
};
