import { getElevateApiConfiguration } from "@/utils/environment-variables";
import { ElevateApi } from "@xxl/product-search-api";
import { serverSideApiInstanceFactory } from "../../shared/helpers";

let elevateApi: ElevateApi<unknown> | undefined;

export const elevateApiInstance = (toggle_elevate_cluster: boolean) =>
  serverSideApiInstanceFactory(
    () =>
      ElevateApi.getInstance(
        getElevateApiConfiguration(toggle_elevate_cluster)
      ),
    elevateApi
  );
