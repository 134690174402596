import { fetchBrandProductList } from "./product-search/elevate/brand/brand-product-list";
import { fetchCampaignProductList } from "./product-search/elevate/campaign/campaign-product-list";
import { fetchCategoryProductList } from "./product-search/elevate/category/category-product-list";
import { fetchSearchProductList } from "./product-search/elevate/search/search-product-list";

export const fetchProductList = {
  brand: fetchBrandProductList,
  campaign: fetchCampaignProductList,
  category: fetchCategoryProductList,
  search: fetchSearchProductList,
};
