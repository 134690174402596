import type { Store as StoreWithId } from "@/react-utils/Stores/stores-helper";

/**
 * Removing these detailed attributes, not needed for many cases
 */
const getBasicStoreData = (stores: StoreWithId[]): StoreWithId[] =>
  stores.map(
    ({
      description,
      contact,
      googlePlaceId,
      linkButton,
      lastModified,
      ...storeWithoutDetails
    }) => storeWithoutDetails
  );

export { getBasicStoreData };
