import { BRAND_ATTRIBUTE_NAME_FOR_ELEVATE } from "@/react-components/Search/Constants";
import type { FacetData } from "@xxl/product-search-api";
import { getLandingPageResults } from "../landing-page/landing-page";
import type {
  CommonLandingPageRequestProps,
  CommonRequestPropsV2,
  CommonResponseProps,
} from "../types";
import { mapCommonRequestData, mapLandingPageResponseData } from "../utils";

type BrandProductListRequestData = CommonRequestPropsV2 &
  CommonLandingPageRequestProps & {
    brandCode: string;
  };
export type BrandProductListResponseData = CommonResponseProps;

export const fetchBrandProductList = async (
  args: BrandProductListRequestData
): Promise<BrandProductListResponseData> => {
  const { brandCode } = args;
  const commonArgs = await mapCommonRequestData({
    ...args,
    isArticleNumbersSearch: false,
  });
  const { data } = await getLandingPageResults(args)(
    {
      ...commonArgs,
      brandCode,
    },
    false
  );

  const isValidBrandFacet = (facet: {
    id?: string;
    label?: string;
    type?: string;
  }): facet is FacetData =>
    facet.id !== undefined &&
    facet.id !== BRAND_ATTRIBUTE_NAME_FOR_ELEVATE &&
    facet.type !== undefined &&
    facet.label !== undefined;

  return mapLandingPageResponseData({
    ...args,
    ...data,
    primaryList: {
      ...data.primaryList,
      facets: data.primaryList.facets.filter(isValidBrandFacet),
    },
  });
};
