import { getLandingPageResults } from "../landing-page/landing-page";
import type {
  CommonLandingPageRequestProps,
  CommonRequestPropsV2,
  CommonResponseProps,
} from "../types";
import { mapCommonRequestData, mapLandingPageResponseData } from "../utils";

type CampaignProductListRequestData = CommonRequestPropsV2 &
  CommonLandingPageRequestProps & {
    campaignId: string;
    categoryIds?: string[];
    additionalCampaignIds?: string[];
  };
export type CampaignProductListResponseData = CommonResponseProps;

export const fetchCampaignProductList = async (
  args: CampaignProductListRequestData
): Promise<CampaignProductListResponseData> => {
  const { additionalCampaignIds, campaignId, categoryIds } = args;
  const commonArgs = await mapCommonRequestData({
    ...args,
    isArticleNumbersSearch: false,
  });

  const { data } = await getLandingPageResults(args)(
    {
      ...commonArgs,
      additionalCampaignIds,
      campaignId,
      categoryIds,
    },
    false
  );

  return mapLandingPageResponseData({
    ...data,
    ...args,
  });
};
