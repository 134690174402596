import { prepareContentLists } from "@/react-app/api/elevate-api";
import type { ElevateApi, SearchPageQuery } from "@xxl/product-search-api";
import { clientAndServerApiFunctionFactory } from "../../../shared/helpers";
import type { ApiOptions } from "../../../shared/types";
import { elevateApiInstance } from "../api-instance-util";

export type GetSearchResultsApiOptions = ApiOptions<
  ElevateApi<unknown>["storefront"]["searchPage"]
>;

const getSearchResults =
  ({
    options,
    toggle_elevate_cluster_search,
  }: {
    options: GetSearchResultsApiOptions;
    toggle_elevate_cluster_search: boolean;
  }) =>
  (query: SearchPageQuery) => {
    const apiFunction = clientAndServerApiFunctionFactory(
      options,
      () =>
        elevateApiInstance(toggle_elevate_cluster_search).storefront.searchPage
    );

    return apiFunction(query, {
      contentLists: prepareContentLists(query.limit),
    });
  };

export { getSearchResults };
