import type { ContentName } from "@/react-app/api/elevate-api";
import type { SearchSuggestionsType } from "@/react-components/Search/SearchSuggestions";
import { isEmpty, isNotNull } from "@xxl/common-utils";
import { log } from "@xxl/logging-utils";
import type { SearchPageQuery } from "@xxl/product-search-api";
import { ElevateQuery, typeOfQuery } from "@xxl/product-search-api";
import { ARTICLE_NUMBERS_SEARCH_REGEX } from "../constants";
import {
  getLandingPageResults,
  type GetLandingPageApiOptions,
} from "../landing-page/landing-page";
import {
  convertContentListsToTabs,
  getCorrectTabNameOrFirstOrNull,
  isValidSearchQuery,
  prepareSearchSuggestions,
  type TabWithTranslationKeyAndContent,
} from "../search-helper";
import {
  getSearchResults,
  type GetSearchResultsApiOptions,
} from "../search-page/search-page";
import type { CommonRequestPropsV2, CommonResponseProps } from "../types";
import { mapCommonRequestData, mapCommonResponseData } from "../utils";

export type SearchPageResponseData = CommonResponseProps & {
  isArticleNumbersSearch: boolean;
  query: string | null;
  searchSuggestions: SearchSuggestionsType;
  selectedTab: ContentName | null;
  tabs: TabWithTranslationKeyAndContent[];
  autoCorrect?: string;
};

type FetchSearchPageData = CommonRequestPropsV2 & {
  apiOptionsForLandingPage: GetLandingPageApiOptions;
  apiOptionsForSearch: GetSearchResultsApiOptions;
  toggle_elevate_cluster_search: boolean;
  toggle_force_member_price_display: boolean;
};

export async function fetchSearchProductList(
  args: FetchSearchPageData
): Promise<SearchPageResponseData> {
  const { query: pagePropsQuery, toggle_elevate_cluster_search } = args;
  const { query, tab } = pagePropsQuery;

  if (typeof query !== "string" || query.length === 0) {
    throw Error(
      `Invalid search query. Query is type "${typeof query}" and has length "${query?.length ?? 0}".`
    );
  }

  /**
   * Defines article numbers search query - used e.g., by content team in banners.
   * For this type of search the availability and stores from cookie are ignored, instead we look for all possible hits
   */
  const isArticleNumbersSearch =
    isValidSearchQuery(query) && ARTICLE_NUMBERS_SEARCH_REGEX.test(query);
  const commonArgs = await mapCommonRequestData({
    ...args,
    isArticleNumbersSearch,
  });
  const searchPageArgs: SearchPageQuery = {
    ...commonArgs,
    ...{
      q: query,
    },
  };
  const { data } =
    typeOfQuery(query) === ElevateQuery.NORMAL
      ? await getSearchResults({
          options: args.apiOptionsForSearch,
          toggle_elevate_cluster_search,
        })(searchPageArgs)
      : await getLandingPageResults({
          options: args.apiOptionsForLandingPage,
          toggle_elevate_cluster_landing_page: toggle_elevate_cluster_search,
        })(searchPageArgs, false);
  const noProductsFound = isEmpty(data.primaryList.baseProducts);
  const noOtherContentFound = data.contentLists.every(({ items }) =>
    isEmpty(items)
  );

  if (noProductsFound && noOtherContentFound) {
    log.info("🙅‍♀️ no results");
    throw new Error("No results found.");
  }

  const mappedResponseData = await mapCommonResponseData({
    primaryList: data.primaryList,
    ...args,
  });
  const { contentLists } = data;
  const tabs = convertContentListsToTabs(
    contentLists,
    mappedResponseData.totalHits
  );
  const selectedTab = isNotNull(tabs)
    ? getCorrectTabNameOrFirstOrNull(tab, tabs, noProductsFound)
    : null;
  const searchSuggestions = prepareSearchSuggestions(
    "relatedSearches" in data ? data.relatedSearches : undefined
  );

  return {
    ...mappedResponseData,
    ...("autoCorrect" in data &&
      data.autoCorrect?.q !== undefined && {
        autoCorrect: data.autoCorrect.q,
      }),
    query: typeof query === "string" && query.length > 0 ? query : null,
    searchSuggestions,
    selectedTab,
    tabs,
    isArticleNumbersSearch,
  };
}
