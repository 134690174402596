import { URL_PARAMETERS } from "@/components/ProductListV2/constants";
import { FILTER_SEPARATOR } from "@/react-app/constants";
import { getIsDiscountCategoryCode } from "@/react-utils/xxl-category";
import { hasValue } from "@xxl/common-utils";
import type { LongTailData } from "@xxl/frontend-api";
import type {
  LandingPageQuery,
  LandingPageRequestSettings,
  SubCategory,
} from "@xxl/product-search-api";
import { getLandingPageResults } from "../landing-page/landing-page";
import {
  getSelectedFiltersFromUrlParameters,
  hasValidBreadcrumbData,
} from "../search-helper";
import type {
  CommonLandingPageRequestProps,
  CommonRequestPropsV2,
  CommonResponseProps,
} from "../types";
import { mapCommonRequestData, mapLandingPageResponseData } from "../utils";

type CategoryProductListRequestData = CommonRequestPropsV2 &
  CommonLandingPageRequestProps & {
    categoryId: string;
    longTailFacets: LongTailData[] | null;
    longTailPattern: string | null;
    promoCategoryCode?: string;
  };
export type CategoryProductListResponseData = CommonResponseProps & {
  breadcrumbs: {
    label: string;
    path: string;
  }[];
  categoryId: string;
  isPublished: boolean;
  longTailFacets: LongTailData[] | null;
  longTailPattern: string | null;
  parentCategory: {
    label: string;
    path: string;
    seoFriendlyURL: string;
  } | null;
  subCategories: SubCategory[];
};

const getLongTailFacets = (longTailFacets: LongTailData[]) => {
  const longTailFacetsWithValues = longTailFacets.filter(({ values }) =>
    hasValue(values)
  );
  const selectedLongTailFilters = longTailFacetsWithValues.map(
    ({ id, values }) => ({
      [`${URL_PARAMETERS.facet.name}${id}`]: values.join(FILTER_SEPARATOR),
    })
  );
  const selectedLongTailFiltersFormatted = longTailFacetsWithValues.reduce(
    (acc, { id, values }) => ({ ...acc, [id]: values }),
    {} as { [x: string]: string[] }
  );

  return {
    selectedLongTailFilters: selectedLongTailFilters.reduce(
      (acc, current) => ({ ...acc, ...current }),
      {}
    ),
    selectedLongTailFiltersFormatted,
  };
};

export const fetchCategoryProductList = async (
  args: CategoryProductListRequestData
): Promise<CategoryProductListResponseData> => {
  const commonArgs = mapCommonRequestData({
    ...args,
    isArticleNumbersSearch: false,
  });
  const { categoryId, longTailFacets, longTailPattern, query } = args;
  const isDiscountCategoryCode = getIsDiscountCategoryCode(categoryId);
  const { selectedLongTailFilters, selectedLongTailFiltersFormatted } =
    hasValue(longTailFacets)
      ? getLongTailFacets(longTailFacets)
      : {
          selectedLongTailFilters: null,
          selectedLongTailFiltersFormatted: null,
        };
  //If we recognize that category is a promo category, we need ask for dynamic pageReference attribute.
  const pageReference = `${isDiscountCategoryCode ? "/dynamic/" : "/"}c/${categoryId}`;
  const categoryLandingPageArgs: LandingPageQuery = {
    ...commonArgs,
    ...selectedLongTailFilters,
    pageReference,
  };

  const initialData: LandingPageRequestSettings = {
    primaryList: {
      include: true,
      productRules: `rule incl custom.categoryIds { "${String(
        categoryId
      )}" } excl custom.isOnCampaign { "false"}  rule  incl custom.categoryIds { "${String(
        categoryId
      )}" } excl custom.isOnPromotion { "false"}`,
    },
  };
  const { data } = await getLandingPageResults(args)(
    categoryLandingPageArgs,
    true,
    isDiscountCategoryCode ? initialData : undefined
  );
  const { navigation } = data;
  const subCategories = navigation?.subCategories ?? [];
  const { breadcrumbs: breadcrumbsFromApi = [] } = navigation ?? {};
  const filteredBreadcrumbs = breadcrumbsFromApi.filter(hasValidBreadcrumbData);
  const mappedBreadcrumbs = filteredBreadcrumbs.map(
    ({ label, seoFriendlyURL: path }) => ({ label, path })
  );
  const { label, path, seoFriendlyURL } =
    filteredBreadcrumbs
      .filter(({ path: p }) => !p.includes(String(categoryId)))
      .at(-1) ?? {};
  const parentCategory =
    hasValue(label) && hasValue(path) && hasValue(seoFriendlyURL)
      ? {
          label,
          path,
          seoFriendlyURL,
        }
      : null;
  const emptyFirstBreadcrumb = { label: "", path: "" };
  const breadcrumbs = [emptyFirstBreadcrumb, ...mappedBreadcrumbs];
  const { selectedFiltersFormatted } =
    getSelectedFiltersFromUrlParameters(query);

  return {
    ...mapLandingPageResponseData({
      ...data,
      ...args,
    }),
    categoryId,
    breadcrumbs,
    longTailFacets,
    longTailPattern,
    parentCategory,
    selectedFilters: {
      ...selectedFiltersFormatted,
      ...selectedLongTailFiltersFormatted,
    },
    subCategories,
  };
};
