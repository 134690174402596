import type { EcomSiteUidLegacy } from "@/react-app/global";
import type { NumberOfColumnsPerDeviceOnPlp } from "@/react-components/ProductList/hooks/useSelectedColumnsNumber/useSelectedColumnsNumber.types";
import { AVAILABILITY } from "@/react-components/Search/SearchFetchProductsHelper.types";
import { DEFAULT_AVAILABILITY } from "@/react-components/Sort/AvailabilitySelector/constants";
import { getPriceId } from "@/react-hooks/useElevateRequestData/useElevateRequestData";
import type { PreferredStores } from "@/react-utils/Cookie";
import { cookieNames, parseCookie } from "@/react-utils/Cookie";
import { toProductCardDataFromBase } from "@/react-utils/ProductData/product-card-data-helper";
import { getBasicStoreData } from "@/react-utils/Stores/store-data";
import {
  convertSiteUidToHost,
  legacySiteUidToSiteUid,
} from "@/react-utils/xxl-shared-data";
import { hasNoValue } from "@xxl/common-utils";
import type {
  LandingPageResultData,
  PrimaryListData,
  SortType,
} from "@xxl/product-search-api";
import type { IncomingHttpHeaders } from "http";
import type { ParsedUrlQuery } from "querystring";
import type { Store as StoreWithId } from "react-app/src/utils/Stores/stores-helper";
import { parametersToRequest } from "../../../../components/ProductListV2/hooks/utils";
import { getElevateDeviceTypeFromHeaders } from "../../../app-page-helper";
import { getSerializableProductData } from "../../../search-api-utils/product-data-maps";
import {
  getChannel,
  getIsLoggedIn,
  getSelectedFiltersFromUrlParameters,
  isValidSearchQuery,
  sanitizeFacetsForDisplay,
} from "./search-helper";

const serializePrimaryList = (
  primaryList: PrimaryListData
): PrimaryListData => ({
  ...primaryList,
  baseProducts: primaryList.baseProducts.map(getSerializableProductData),
});

const getAvailability = ({
  cookies,
  stores,
  isArticleNumbersSearch,
}: {
  cookies: Partial<{
    [key: string]: string;
  }>;
  siteUid: EcomSiteUidLegacy;
  stores: StoreWithId[];
  isArticleNumbersSearch: boolean;
}) => {
  const storeIds = stores.map((store) => store.id);
  if (isArticleNumbersSearch) {
    // In case of article number search we ignore availability & store settings from cookies
    return {
      availability: DEFAULT_AVAILABILITY,
      storesData: stores,
      validSelectedStores: storeIds,
    };
  }
  const preferredStoresCookie = cookies[cookieNames.PREFERRED_STORES];
  const preferredStores =
    preferredStoresCookie !== undefined
      ? parseCookie<PreferredStores>(
          preferredStoresCookie,
          cookieNames.PREFERRED_STORES
        )
      : null;
  const {
    availability: availabilityFromCookie,
    ids: selectedStoresFromCookie,
  } = preferredStores ?? {};
  const storeIdsFromCookie = selectedStoresFromCookie;
  const shouldAddDefaultStoreAvailability = hasNoValue(storeIdsFromCookie);
  const availability: AVAILABILITY[] =
    availabilityFromCookie ??
    (shouldAddDefaultStoreAvailability
      ? DEFAULT_AVAILABILITY
      : [AVAILABILITY.ONLINE]);

  const selectedStores = storeIdsFromCookie ?? storeIds;
  const validSelectedStores = shouldAddDefaultStoreAvailability
    ? storeIds
    : selectedStores.filter((store) => storeIds.includes(store));

  return {
    availability,
    storesData: stores,
    validSelectedStores,
  };
};

type CommonResponseData = {
  primaryList: PrimaryListData;
  cookies: Partial<{
    [key: string]: string;
  }>;
  siteUid: EcomSiteUidLegacy;
  stores: StoreWithId[];
  query: ParsedUrlQuery;
};

export const mapCommonResponseData = ({
  primaryList,
  cookies,
  siteUid,
  query,
  stores,
}: CommonResponseData) => {
  const serializedPrimaryList = serializePrimaryList(primaryList);
  const { facets: initialFacets, totalHits } = serializedPrimaryList;
  const facets = sanitizeFacetsForDisplay(initialFacets);
  const products = serializedPrimaryList.baseProducts.map((product) =>
    toProductCardDataFromBase(product)
  );
  const sortOrderData = serializedPrimaryList.sort;
  const xxlNumberOfColumnsPerDeviceOnPlpCookie =
    cookies[cookieNames.XXL_NUMBER_OF_COLUMNS_PER_DEVICE_ON_PLP];
  const numberOfColumnsPerDeviceOnPlp =
    parseCookie<NumberOfColumnsPerDeviceOnPlp | null>(
      xxlNumberOfColumnsPerDeviceOnPlpCookie ?? "",
      cookieNames.XXL_NUMBER_OF_COLUMNS_PER_DEVICE_ON_PLP
    );
  const {
    availability,
    storesData: fullStoresData,
    validSelectedStores,
  } = getAvailability({
    cookies,
    siteUid,
    stores,
    isArticleNumbersSearch: false,
  });
  const storesData = getBasicStoreData(fullStoresData);
  const { selectedFiltersFormatted } =
    getSelectedFiltersFromUrlParameters(query);

  return {
    availability,
    facets,
    numberOfColumnsPerDeviceOnPlp,
    products,
    selectedFilters: selectedFiltersFormatted,
    sortOrderData,
    storesData,
    storeIds: validSelectedStores,
    totalHits,
  };
};

export const mapLandingPageResponseData = (
  args: CommonResponseData & {
    isPublished: LandingPageResultData["isPublished"];
  }
) => {
  return {
    ...mapCommonResponseData(args),
    isPublished: args.isPublished,
  };
};

/**
 * Maps request data that is common for Elevate landing-pages and search-page.
 */
export const mapCommonRequestData = ({
  cookies,
  forceAnonymousUser = false,
  headers,
  isArticleNumbersSearch,
  isTeamsales,
  numberOfProductsPerPage,
  query,
  siteUid,
  stores,
  toggle_force_member_price_display,
  userKeys,
}: {
  cookies: Partial<{
    [key: string]: string;
  }>;
  isArticleNumbersSearch: boolean; // not needed for landing-page, consider refactor
  forceAnonymousUser?: boolean;
  headers: IncomingHttpHeaders;
  isTeamsales: boolean;
  numberOfProductsPerPage: number;
  query: ParsedUrlQuery;
  siteUid: EcomSiteUidLegacy;
  stores: StoreWithId[];
  toggle_force_member_price_display: boolean;
  userKeys: {
    customerKey: string;
    sessionKey: string;
  };
}) => {
  const { availability, validSelectedStores } = getAvailability({
    cookies,
    siteUid,
    stores,
    isArticleNumbersSearch,
  });
  const { pages, query: searchQuery, sort, ...otherQueryParams } = query;
  const { customerKey, sessionKey } = userKeys;
  const modernSiteUid = legacySiteUidToSiteUid(siteUid);

  const {
    channels,
    limit,
    skip,
    sort: sortParsed,
    storeIds: _storeIds,
  } = parametersToRequest({
    availability,
    numberOfProductsPerPage,
    page: typeof pages !== "string" ? "0" : pages,
    query: isValidSearchQuery(searchQuery) ? searchQuery : "",
    sort: (typeof sort !== "string" ? undefined : sort) as SortType,
    selectedStores: validSelectedStores,
  });
  const { selectedFilters } =
    getSelectedFiltersFromUrlParameters(otherQueryParams);

  return {
    channels: getChannel(channels),
    customerKey,
    limit,
    sessionKey,
    site: convertSiteUidToHost(modernSiteUid),
    skip,
    sort: sortParsed,
    ...(_storeIds !== undefined && {
      stores: _storeIds,
    }),
    touchpoint: getElevateDeviceTypeFromHeaders(headers),
    priceId: forceAnonymousUser
      ? "anonymous"
      : getPriceId(
          getIsLoggedIn(cookies),
          isTeamsales,
          toggle_force_member_price_display
        ),
    ...selectedFilters,
    ...(typeof searchQuery === "string" && { q: searchQuery }),
  };
};
